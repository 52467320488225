@media (prefers-color-scheme: dark) {
  .app {
    background-color: #282c34;
    color: white;
  }

  .loading {
    color: white;
    background-color: #282c34;
  }
}

.loading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.header > h1 {
  font-size: clamp(23px, calc(1.4375rem + ((1vw - 3.75px) * 0.4531)), 30px);
  font-weight: bold;
  margin-top: auto;
  margin-bottom: 1vh;
}
.header > p {
  font-size: clamp(11px, calc(0.6875rem + ((1vw - 3.75px) * 0.0647)), 12px);
  font-weight: bold;
  margin-bottom: auto;
}

.task-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}