.task-card {
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px;
  width: 85vw;
  overflow: hidden;
  position: relative;
}

.task-card-header {
  align-items: center;
  background-color: #f5f5f5;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 0 16px;
  font-size: clamp(18px, calc(1.125rem + ((1vw - 3.75px) * 0.4531)), 25px);
  min-height: 0vw;
  font-weight: 500;
  line-height: 1.5;
  color: #333333;
}

.task-card-header--overdue {
  background-color: #f096a9;
}

.task-card-header--due-today {
  background-color: #f7eea0;
}

.task-card-body {
  flex: 1;
  padding: 16px;
  font-size: clamp(14px, calc(0.875rem + ((1vw - 3.75px) * 0.3883)), 20px);
  min-height: 0vw;
  line-height: 1.5;
  word-break: break-all;
}

.task-card-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-top: 1px solid #d6d6d6;
  font-size: clamp(11px, calc(0.75rem + ((1vw - 3.75px) * 0.0971)), 12px);
  min-height: 0vw;
  line-height: 1.5;
  color: #536a6c ;
}

.task-card-url {
  text-decoration: none;
  font-size: clamp(12px, calc(0.75rem + ((1vw - 3.75px) * 0.0971)), 13.5px);
  min-height: 0vw;
}

.task-card-url:hover {
  text-decoration: underline;
}

.task-card-url > a {
  color: #287fb8;
  text-decoration: none;
}

.task-card-url > a:visited {
  color: purple;
}

@media (prefers-color-scheme: dark) {
  .task-card {
    background-color: #282c34;
    border: 1px solid #7d7575;
    box-shadow: 0 6px 8px rgba(226, 218, 218, 0.177);
  }

  .task-card-header {
    background-color: #9e9d9d;
    border-bottom: 1px solid #d6d6d6;
    color: #25371f;
    font-weight: 550;
  }

  .task-card-header--overdue {
    background-color: #f096a9;
  }

  .task-card-header--due-today {
    background-color: #f7eea0;
  }

  .task-card-footer {
    color: #faeaee ;
  }

  .task-card-url > a {
    color: #5bb5f0;
  }
}